import { Dialog, DialogTitle } from '@material-ui/core';

const TeamStats = ({ open, setOpen }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Estatísticas da equipe</DialogTitle>
    </Dialog>
  );
};

export default TeamStats;
