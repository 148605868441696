import Axios from 'axios';

const api = {
  axios: Axios.create({
    // baseURL: 'http://localhost:5001/jornada-inovacao/us-central1/api',
    baseURL: 'https://us-central1-jornada-inovacao.cloudfunctions.net/api',
    // baseURL: 'https://b977459f8b12.ngrok.io/jornada-inovacao/us-central1/api',
    // baseURL: 'https://7f0036d8e29b.ngrok.io/jornada-inovacao/us-central1/',
  }),
  getAllClasses: async () => {
    const result = await api.axios.get('/classes');
    return result;
  },
  login: async (data) => {
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = await api.axios.post('/admin/login', data, config);
    return result;
  },
  activateUser: async (userId) => {
    const result = api.axios.post(`/activate-user/${userId}`);
    return result;
  },
  changeUserRole: async (userId, newRole) => {
    const result = api.axios.post(`/change-user-role/${userId}/${newRole}`);
    return result;
  },
  getUsers: async () => {
    const result = await api.axios.get('/allusers');
    return result;
  },
  createNewClass: async (data) => {
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = await api.axios.post('/classes', data, config);
    return result;
  },
  getClassDetail: async (classId) => {
    const result = await api.axios.get(`/details/${classId}`);
    return result;
  },
  createNewProject: async (data) => {
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = await api.axios.post('/projects', data, config);
    return result;
  },
  addComentToStep: async (projectId, stepNumber, considerations) => {
    const result = api.axios.post(
      `/considerations/${projectId}/${
        parseInt(stepNumber) - 1
      }/${considerations}`
    );
    return result;
  },
  getProjectDetails: async (projectId) => {
    const result = api.axios.get(`/project-details/${projectId}`);
    return result;
  },
  getProjectsSimpleList: async () => {
    const result = api.axios.get(`/projects-simple-list`);
    return result;
  },
  getClassesSimpleList: async () => {
    const result = api.axios.get(`/classes-simple-list`);
    return result;
  },
  addUserToClass: async (classId, userId, userName) => {
    const data = { userId, userName };
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = api.axios.post(
      `/add-user-to-class/${classId}`,
      data,
      config
    );
    return result;
  },
  getUsersSimpleList: async () => {
    const result = api.axios.get(`/users-simple-list`);
    return result;
  },
  addCoordinatorToClass: async (classId, user) => {
    const data = user;
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = api.axios.post(
      `/add-coordinator-to-class/${classId}`,
      data,
      config
    );
    return result;
  },
  addMemberToTeam: async (projectId, user) => {
    const data = user;
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = api.axios.post(
      `/add-member-to-project/${projectId}`,
      data,
      config
    );
    return result;
  },
  addMentorToTeam: async (projectId, user) => {
    const data = user;
    const config = {
      headers: { 'Content-Type': 'application/json' },
    };
    const result = api.axios.post(
      `/add-mentor-to-project/${projectId}`,
      data,
      config
    );
    return result;
  },
  getAllUsers: async () => {
    const result = await api.axios.get('/options-users');
    return result;
  },
};

export default api;
