import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

const ProjectLine = ({
  projectName = 'a',
  projectActualStep = 0,
  classActualStep = 0,
}) => {
  return (
    <div style={{ marginBottom: 20 }}>
      <div style={{ width: '12%', display: 'inline-block' }}>
        <Typography>{projectName}</Typography>
      </div>
      <div
        style={{
          backgroundColor: '#00b2a9',
          color: '#00b2a9',
          display: 'inline-block',
          width: `${projectActualStep * 4}%`,
          minHeight: '100%',
        }}
      >
        {projectActualStep}
      </div>
      {projectActualStep < classActualStep && (
        <div
          style={{
            backgroundColor: '#ff4242',
            color: '#ff4242',
            display: 'inline-block',
            width: `${(classActualStep - projectActualStep) * 4}%`,
          }}
        >
          a
        </div>
      )}
      <div
        style={{
          backgroundColor: '#e5e5e5',
          color: '#e5e5e5',
          display: 'inline-block',
          width: `${
            (22 -
              projectActualStep -
              (classActualStep - projectActualStep < 0
                ? 0
                : classActualStep - projectActualStep)) *
            4
          }%`,
        }}
      >
        a
      </div>
    </div>
  );
};

const TeamsStats = ({ open, setOpen, teams = [], actualStep = 0 }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle style={{ textAlign: 'center' }}>
        Estatísticas das equipes
      </DialogTitle>
      <DialogContent>
        {teams.map((t) => (
          <ProjectLine
            projectName={t.name}
            projectActualStep={t.actualStep}
            classActualStep={actualStep}
          />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default TeamsStats;
