import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Spacer from "../../../../components/Spacer";
import api from "../../../../services/api";

const AddMentorToTeam = ({ open, setOpen }) => {
  const [allUser, setAllUsers] = useState([]);
  const [tab, setTab] = useState(0);
  const [user, setSelectedUser] = useState();
  const [filter, setFilter] = useState("");
  const { projectId } = useParams();
  const fetchAllUsers = () => {
    api.getUsersSimpleList().then((res) => {
      setAllUsers(res.data);
      // console.log(res.data);
    });
  };
  useEffect(() => {
    fetchAllUsers();
  }, []);
  const addMentorToTeam = () => {
    api
      .addMentorToTeam(projectId, user)
      .then(() => {
        setTab(0);
        setOpen(false);
      })
      .catch((error) => console.log(error));
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setTab(0);
      }}
    >
      {tab === 0 && (
        <>
          <DialogTitle>Selecione um Mentor</DialogTitle>
          <DialogContent>
            <Typography>
              Ao adicionar um Mentor ao projeto, você o autoriza a avaliar os
              passos finalizados e validar cada ciclo da equipe.{" "}
              <strong>Recomendado para monitores.</strong>
            </Typography>
            <Spacer size="1.5em" />
            <input
              autoFocus
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Pesquisar por nome de mentor"
              style={{
                width: "100%",
                boxSizing: "border-box",
                padding: "8px",
              }}
            />
            <Spacer size="1.5em" />
            <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
              {allUser
                .filter((u) => u.name.includes(filter))
                .filter((u) => u.type === "admin")
                .map((u) => (
                  <div
                    onClick={() => {
                      setSelectedUser(u);
                      setTab(1);
                    }}
                    style={{
                      cursor: "pointer",
                      border: "1px solid rgba(0,0,0,0.3)",
                      borderRadius: 10,
                      padding: "1em",
                    }}
                  >
                    <Typography>{u.name}</Typography>
                  </div>
                ))}
            </div>
            <Spacer />
          </DialogContent>
        </>
      )}
      {tab === 1 && (
        <>
          <DialogTitle>Confirmar adição</DialogTitle>
          <DialogContent>
            <Typography style={{ marginBottom: "2em" }}>
              Deseja confirmar essa ação?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                // setAddCoordinator(false);
                setTab(0);
              }}
              variant="contained"
            >
              Cancelar
            </Button>
            <Button
              // onClick={addCoordinatorToClass}
              onClick={addMentorToTeam}
              variant="contained"
              color="primary"
            >
              Confirmar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AddMentorToTeam;
