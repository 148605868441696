import Breadcrumb from '../../components/Breadcrumb';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';

const ClassConfig = () => {
  return (
    <>
      <Header />
      <Breadcrumb
        locations={[
          { name: 'Turmas', path: '/' },
          {
            name: localStorage.getItem('className'),
            path: `/class/${localStorage.getItem('classId')}`,
          },
        ]}
      />
      <PageTitle title="Configurações" />
    </>
  );
};

export default ClassConfig;
