import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { IoEye } from 'react-icons/io5';
import { BiCommentDetail } from 'react-icons/bi';
import { useHistory, useParams } from 'react-router';
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import api from '../../services/api';
import ActionButton from '../../components/ActionButton';
import Spacer from '../../components/Spacer';
import AddMemberToTeam from './Modals/AddMemberToTeam';
import AddMentorToTeam from './Modals/AddMentorToTeam';
import TeamDetails from './Modals/TeamDetails';
import TeamStats from './Modals/TeamStats';

const Project = () => {
  const [projetcDetails, setProjetcDetails] = useState(false);
  const [questsAndDet, setQuestionsAndDet] = useState(false);
  const [fetchingProjDet, setFetchingProjDet] = useState(true);
  const [modalAddMember, setModalAddMember] = useState(false);
  const [modalAddMentor, setModalAddMentor] = useState(false);
  const [modalTeamDetails, setModalTeamDetails] = useState(false);
  const [modalTeamStats, setModalTeamStats] = useState(false);
  const { projectId } = useParams();
  const { push } = useHistory();

  const fetchProjectDetails = () => {
    setFetchingProjDet(true);
    api
      .getProjectDetails(projectId)
      .then((res) => {
        setProjetcDetails(res.data);
        // console.log(res.data.steps);
        setQuestionsAndDet(
          res.data.steps.map((s, i) => ({
            step: s.stepNumber,
            questions: s.questions,
            interviews: s.interviews,
            considerations: s.considerations,
          }))
        );
      })
      .finally(() => setFetchingProjDet(false));
  };
  useEffect(() => {
    fetchProjectDetails(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      {!fetchingProjDet && (
        <>
          <Breadcrumb
            locations={[
              { name: 'Turmas', path: '/' },
              {
                name: localStorage.getItem('className'),
                path: `/class/${localStorage.getItem('classId')}`,
              },
              {
                name: localStorage.getItem('projectName'),
                path: `/project/${localStorage.getItem('projectId')}`,
              },
            ]}
          />
          <PageTitle title={projetcDetails.name} />
          <AddMemberToTeam open={modalAddMember} setOpen={setModalAddMember} />
          <AddMentorToTeam open={modalAddMentor} setOpen={setModalAddMentor} />
          <TeamDetails open={modalTeamDetails} setOpen={setModalTeamDetails} />
          <TeamStats open={modalTeamStats} setOpen={setModalTeamStats} />
          <Container>
            <p style={{ marginTop: 0, maxWidth: '50%', marginBottom: '1em' }}>
              {projetcDetails.description}
            </p>
            <p style={{ marginTop: 0, maxWidth: '50%', marginBottom: '3em' }}>
              <strong>Membros: </strong>
              {projetcDetails.members.map((u) => u.name).join(', ')}
            </p>
          </Container>
          <Container>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <Typography style={{ fontWeight: 500, fontSize: '1.3em' }}>
                Ações
              </Typography>
              <ActionButton
                title="Adicionar membro"
                onClick={() => setModalAddMember(true)}
              />
              <ActionButton
                title="Adicionar mentor"
                onClick={() => setModalAddMentor(true)}
              />
              {/* <ActionButton
                title="Ver detalhes do projeto"
                onClick={() => setModalTeamDetails(true)}
              /> */}
              {/* <ActionButton
                title="Ver estatísticas do projeto"
                onClick={() => setModalTeamStats(true)}
              /> */}
              {/* <ActionButton
                title="Pitch final"
                pitch
                onClick={() => setModalTeamStats(true)}
              /> */}
            </div>
          </Container>
          <Spacer size="3em" />
          <Container style={{ minHeight: '50vh', marginBottom: '5em' }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Passo</TableCell>
                  <TableCell align="center">Respondeu às questões</TableCell>
                  <TableCell align="center">Entrevistas</TableCell>
                  <TableCell align="right">Observações do avaliador</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {console.log(questsAndDet)} */}
                {questsAndDet.map((p) => (
                  <TableRow key={p.stepNumber}>
                    <TableCell component="th" scope="row" align="left">
                      Passo {parseInt(p.step) < 10 ? '0' + p.step : p.step}
                    </TableCell>
                    <TableCell align="center">
                      {p.questions ? 'Sim' : p.step === 3 ? 'Não se aplica' : 'Não'}
                    </TableCell>
                    <TableCell align="center">
                      {p.interviews === 0 ? '-' : p.interviews}
                    </TableCell>
                    <TableCell align="right">
                      {p.questions ? (
                        p.considerations ? (
                          <IoEye
                            style={{ cursor: 'pointer' }}
                            size={25}
                            onClick={() => {
                              localStorage.setItem('stepNumber', p.step);
                              push(`/steps/${projetcDetails.id}/${p.step}`);
                            }}
                          />
                        ) : (
                          <BiCommentDetail
                            style={{ cursor: 'pointer' }}
                            size={25}
                            onClick={() => {
                              localStorage.setItem('stepNumber', p.step);
                              push(`/steps/${projetcDetails.id}/${p.step}`);
                            }}
                          />
                        )
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Container>
        </>
      )}
      <Footer />
    </>
  );
};

export default Project;
