import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import api from '../../../../services/api';

const TeamDetails = ({ open, setOpen }) => {
  const [projectDetails, setProjectDetails] = useState();
  const [fetchingProjectDetails, setFetchingProjectDetails] = useState(true);

  const fetchProjectDetails = () => {
    if (open) {
      setFetchingProjectDetails(true);
      api
        .getProjectDetails(localStorage.getItem('projectId'))
        .then((res) => {
          console.log(res.data);
          setProjectDetails(res.data);
        })
        .finally(() => setFetchingProjectDetails(false));
    }
  };

  useEffect(fetchProjectDetails, [open]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>
        Detalhes da equipe {localStorage.getItem('projectName')}
      </DialogTitle>
      <DialogContent>
        {fetchingProjectDetails ? (
          'Carregando'
        ) : (
          <>
            <Typography>
              <b>Descrição: </b>
              {projectDetails.description}
            </Typography>
            <br />
            <Typography>
              <b>Pontuação: </b>
              {projectDetails.score} pontos de 1000
            </Typography>
            <Typography>
              <b>Passos finalizados: </b>
              {projectDetails.steps
                .filter((s) => !!s.questions)
                .map((s) =>
                  parseInt(s.stepNumber) < 10
                    ? '0' + s.stepNumber
                    : s.stepNumber
                )
                .join(', ')}
            </Typography>
            <Typography>
              <b>Entrevistas realizadas: </b>
              {projectDetails.interviews} de 60
            </Typography>
            <br />
            <Typography>
              <b>Validação do Problema: </b>
              {projectDetails.validations.val1
                ? 'Concluída'
                : projectDetails.steps[6].questions
                ? 'Pendente de avaliação'
                : 'Ciclo não concluído pela equipe'}
            </Typography>
            <Typography>
              <b>Validação da Solução: </b>
              {projectDetails.validations.val2
                ? 'Concluída'
                : projectDetails.steps[13].questions
                ? 'Pendente de avaliação'
                : 'Ciclo não concluído pela equipe'}
            </Typography>
            <Typography>
              <b>Validação do Modelo: </b>
              {projectDetails.validations.val3
                ? 'Concluída'
                : projectDetails.steps[21].questions
                ? 'Pendente de avaliação'
                : 'Ciclo não concluído pela equipe'}
            </Typography>
            <br />
            <Typography>
              <b>Membros: </b>
              {projectDetails.members.map((m) => m.name).join(', ')}
            </Typography>
          </>
        )}
        <div
          style={{ display: 'flex', margin: '2em 0', justifyContent: 'center' }}
        >
          <Button onClick={() => setOpen(false)} variant="contained">
            Fechar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TeamDetails;
