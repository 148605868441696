import { Button, Container, TextField, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import api from '../../services/api';

const StepAvaliation = () => {
  const [projetcDetails, setProjetcDetails] = useState(false);
  const [fetchingProjDet, setFetchingProjDet] = useState(true);
  const [displayAvaliationField, setDisplayAvaliationField] = useState(false);
  const [stepDetails, setStepDetails] = useState();
  const [considerations, setConsiderations] = useState('');
  const { projectId, stepNumber } = useParams();
  const { push } = useHistory();

  const fetchProjectDetails = () => {
    setFetchingProjDet(true);
    api
      .getProjectDetails(projectId)
      .then((res) => {
        // console.log(res.data.steps[parseInt(stepNumber)]);
        setProjetcDetails(res.data);
        // setConsiderations()
        setStepDetails(res.data.steps[parseInt(stepNumber - 1)]);
      })
      .finally(() => setFetchingProjDet(false));
  };

  const addConsiderations = () => {
    api.addComentToStep(projectId, stepNumber, considerations).then(() => {
      fetchProjectDetails();
      setDisplayAvaliationField(false);
    });
  };
  useEffect(() => {
    fetchProjectDetails(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header />
      {projetcDetails && (
        <>
          <Breadcrumb
            locations={[
              { name: 'Turmas', path: '/' },
              {
                name: localStorage.getItem('className'),
                path: `/class/${localStorage.getItem('classId')}`,
              },
              {
                name: localStorage.getItem('projectName'),
                path: `/project/${localStorage.getItem('projectId')}`,
              },
              {
                name: `Passo ${parseInt(stepNumber)}`,
                path: `/steps/${localStorage.getItem(
                  'projectId'
                )}/${stepNumber}`,
              },
            ]}
          />
          <PageTitle title={`${projetcDetails.name}`} />

          {stepDetails && (
            <Container>
              <Typography style={{ fontSize: '1.5em', marginBottom: '2em' }}>
                Respostas passo {parseInt(stepNumber)}
              </Typography>
              {stepDetails.questions.questionsArray.map((q) => (
                <>
                  <Typography
                    style={{
                      fontSize: '1.3em',
                      marginBottom: '0.6em',
                      fontWeight: 500,
                    }}
                  >
                    {q.title}
                  </Typography>
                  <Typography
                    style={{
                      border: '1px #C9C9C9 solid',
                      boxSizing: 'border-box',
                      borderRadius: '6px',
                      padding: '1em',
                      fontSize: '1em',
                      marginBottom: '2em',
                    }}
                  >
                    {q.answer}
                  </Typography>
                </>
              ))}
              <p>
                Observações:{' '}
                {stepDetails.questions.observations ||
                  'Nenhuma observação adicional'}
              </p>
              <p>
                Respostas adicionadas por {stepDetails.questions.user} em{' '}
                {stepDetails.questions.date}
              </p>
              <hr style={{ color: '#c9c9c9', height: 0.6, marginTop: '4em' }} />
              <Typography
                style={{
                  fontSize: '1.3em',
                  marginTop: '2em',
                  marginBottom: '0.6em',
                  fontWeight: 500,
                }}
              >
                Observações do avaliador
              </Typography>
              {stepDetails.considerations && !displayAvaliationField ? (
                <>
                  <p style={{ marginBottom: '4em' }}>
                    {stepDetails.considerations}
                  </p>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant="contained"
                      style={{ marginBottom: '6em' }}
                      onClick={() =>
                        push(`/project/${localStorage.getItem('projectId')}`)
                      }
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: '6em' }}
                      onClick={() => {
                        setConsiderations(stepDetails.considerations);
                        setDisplayAvaliationField(true);
                      }}
                    >
                      Editar avaliação
                    </Button>
                  </div>
                </>
              ) : (
                <div style={{ marginBottom: '6em' }}>
                  {['7', '14', '22'].includes(stepNumber) && (
                    <p
                      style={{
                        fontSize: '1.1em',
                        padding: '1.4em',
                        margin: '1.3em 0',
                        border: '1px solid rgba(0,0,0,0.8)',
                        backgroundColor: 'rgba(0,0,0,0.1)',
                        borderRadius: '1em'
                      }}
                    >
                      <strong>Atenção: </strong>este é um passo de Validação de
                      Ciclo. Ao clicar em "Aprovar para o próximo ciclo", você
                      está autorizando a equipe a avançar para os próximos
                      passos da Jornada.
                    </p>
                  )}
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    placeholder="Adicione um comentário"
                    value={considerations}
                    variant="outlined"
                    style={{ marginBottom: '1em' }}
                    onChange={(e) => setConsiderations(e.target.value)}
                  />
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      variant="contained"
                      onClick={() =>
                        push(`/project/${localStorage.getItem('projectId')}`)
                      }
                    >
                      Voltar
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={considerations === ''}
                      onClick={addConsiderations}
                    >
                      {['7', '14', '22'].includes(stepNumber)
                        ? 'Aprovar para o próximo ciclo'
                        : 'Enviar avaliação'}
                    </Button>
                  </div>
                </div>
              )}
            </Container>
          )}
        </>
      )}
      <Footer />
    </>
  );
};

export default StepAvaliation;
