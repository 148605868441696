const getDiffDays = (starting, ending) => {
  const date1 = new Date(starting);
  const date2 = new Date(ending);
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
};

const getActualStep = (total, actual, goal) => {
  return Math.floor((goal * actual) / total);
};

export const changeActualStep = (classDetails) => {
  const todayDate = new Date().toISOString().slice(0, 10);
  const { startingDate, endingDate, stepsGoal } = classDetails;
  const newActualStep = getActualStep(
    getDiffDays(startingDate, endingDate),
    getDiffDays(startingDate, todayDate),
    stepsGoal
  );
  return { ...classDetails, actualStep: newActualStep };
};
