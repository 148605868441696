import { Typography } from '@material-ui/core';
import { ActionContainer } from './styles';

const ActionButton = ({ title, onClick, pitch }) => {
  return (
    <ActionContainer onClick={onClick} pitch={pitch}>
      <Typography>{title}</Typography>
    </ActionContainer>
  );
};

export default ActionButton;
