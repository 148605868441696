import { Chip, TableRow } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
	0% {opacity: 0; left: 1em}
	100% {opacity: 1; left: 0}
`;

export const Row = styled(TableRow)`
  position: relative;
  opacity: 0;
  left: 1em;
  animation: 0.5s ${fadeIn} normal;
  animation-fill-mode: forwards;
`;

export const StatusChip = styled(Chip)`
  font-size: 1em;
  font-weight: 500;
  color: ${({ color }) => (color === 'yellow' ? '#705614' : 'white')};
  background: ${({ color }) => (color === 'yellow' ? '#FBC02D' : '#43A047')};
`;
