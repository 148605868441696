/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  Container,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { IoEye, IoPersonAdd, IoInformationCircle } from "react-icons/io5";
import Breadcrumb from "../../components/Breadcrumb";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PageTitle from "../../components/PageTitle";
import api from "../../services/api";
import NewProjectModal from "./components/NewProjectModal";
import Spacer from "../../components/Spacer";
import { Row } from "../AllUsers/styles";
import ActionButton from "../../components/ActionButton";
import TeamsStats from "./Modals/TeamsStats";
import AddMemberToTeam from "../Project/Modals/AddMemberToTeam";
import { changeActualStep } from "../../utils/timeFunctions";
import TeamDetails from "../Project/Modals/TeamDetails";

const ClassDetails = () => {
  const [classDetails, setClassDetails] = useState();
  const [classDetailsModal, setClassDetailsModal] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(true);
  const [openNewProject, setOpenNewProject] = useState(false);
  const [allUser, setAllUsers] = useState([]);
  const [addCoordinator, setAddCoordinator] = useState(false);
  const [modalTeamsStats, setModalTeamStats] = useState(false);
  const [modalMemberToTeam, setModalMemberToTeam] = useState(false);
  const [openTeamDetails, setOpenTeamDetails] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [tab, setTab] = useState(0);
  const [filter, setFilter] = useState("");
  const { push } = useHistory();
  const { id } = useParams();

  const fetchClassDetails = () => {
    setFetchingDetails(true);
    api
      .getClassDetail(id)
      .then((res) => {
        setClassDetails({
          ...res.data,
          details: changeActualStep(res.data.details),
        });
        setFetchingDetails(false);
      })
      .catch(() => push("/"));
  };

  const addCoordinatorToClass = () => {
    api
      .addCoordinatorToClass(classDetails.details.id, selectedUser)
      .then(() => {
        setAddCoordinator(false);
        setTab(0);
        fetchClassDetails();
      });
  };

  const fetchAllUsers = () => {
    api.getUsersSimpleList().then((res) => setAllUsers(res.data));
  };

  useEffect(() => {
    fetchClassDetails();
    fetchAllUsers();
  }, []);

  return (
    <>
      <Header />
      <Breadcrumb
        locations={[
          { name: "Turmas", path: "/" },
          {
            name: localStorage.getItem("className"),
            path: `/class/${localStorage.getItem("classId")}`,
          },
        ]}
      />
      <PageTitle
        title={fetchingDetails ? "Carregando" : classDetails.details.name}
      />
      {classDetails && (
        <Dialog
          open={addCoordinator}
          onClose={() => {
            setAddCoordinator(false);
            setTab(0);
          }}
        >
          {tab === 0 && (
            <>
              <DialogTitle>Selecione um Coordenador</DialogTitle>
              <DialogContent>
                <Typography>
                  Ao adicionar um Coordenador à turma, você o autoriza a criar e
                  gerenciar projetos/equipes, ver os detalhes da turma e as
                  estatísticas das equipes.{" "}
                  <strong>Recomendado para professores.</strong>
                </Typography>
                <Spacer size="1.5em" />
                <input
                  autoFocus
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  placeholder="Pesquisar por nome de Coordenador"
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    padding: "8px",
                  }}
                />
                <Spacer size="1.5em" />
                <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
                  {allUser
                    .filter((u) => u.type === "admin")
                    .filter((u) => u.name.includes(filter))
                    .map((u) => (
                      <div
                        onClick={() => {
                          setSelectedUser(u);
                          setTab(1);
                        }}
                        style={{
                          cursor: "pointer",
                          border: "1px solid rgba(0,0,0,0.3)",
                          borderRadius: 10,
                          padding: "1em",
                        }}
                      >
                        <Typography>{u.name}</Typography>
                      </div>
                    ))}
                </div>
                <Spacer />
              </DialogContent>
            </>
          )}
          {tab === 1 && (
            <>
              <DialogTitle>Confirmar adição</DialogTitle>
              <DialogContent>
                <Typography style={{ marginBottom: "2em" }}>
                  Adiconar {selectedUser.name} como Coordenador da turma?
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setAddCoordinator(false);
                    setTab(0);
                  }}
                  variant="contained"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={addCoordinatorToClass}
                  variant="contained"
                  color="primary"
                >
                  Confirmar
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}
      {classDetails && (
        <>
          <TeamsStats
            open={modalTeamsStats}
            setOpen={setModalTeamStats}
            actualStep={classDetails.details.actualStep}
            teams={classDetails.projects}
          />
        </>
      )}
      {classDetails && (
        <TeamDetails open={openTeamDetails} setOpen={setOpenTeamDetails} />
      )}
      {classDetails && (
        <Dialog
          open={classDetailsModal}
          onClose={() => setClassDetailsModal(false)}
          fullWidth
        >
          <DialogTitle>Detalhes da turma</DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", marginBottom: 10 }}>
              <Typography style={{ fontWeight: 500, marginRight: 10 }}>
                Nome da turma:
              </Typography>
              <Typography style={{ fontWeight: 400 }}>
                {classDetails.details.name}
              </Typography>
            </div>
            <div style={{ display: "flex", marginBottom: 10 }}>
              <Typography style={{ fontWeight: 500, marginRight: 10 }}>
                Descrição:
              </Typography>
              <Typography style={{ fontWeight: 400 }}>
                {classDetails.details.description}
              </Typography>
            </div>
            <div style={{ display: "flex", marginBottom: 10 }}>
              <Typography style={{ fontWeight: 500, marginRight: 10 }}>
                Coordenadores:
              </Typography>
              <Typography style={{ fontWeight: 400 }}>
                {classDetails.details.allCoordinators
                  .map((u) => u.name)
                  .join(", ")}
              </Typography>
            </div>
            <div style={{ display: "flex", marginBottom: 10 }}>
              <Typography style={{ fontWeight: 500, marginRight: 10 }}>
                Membros:
              </Typography>
              <Typography style={{ fontWeight: 400 }}>
                {classDetails.details.allUsers
                  .map((u) => u.userName)
                  .join(", ")}
              </Typography>
            </div>
            <div style={{ display: "flex", marginBottom: 10 }}>
              <Typography style={{ fontWeight: 500, marginRight: 10 }}>
                Quantidade de projetos:
              </Typography>
              <Typography style={{ fontWeight: 400 }}>
                {classDetails.projects.length}
              </Typography>
            </div>
          </DialogContent>
        </Dialog>
      )}

      <AddMemberToTeam
        open={modalMemberToTeam}
        setOpen={setModalMemberToTeam}
        project={selectedProjectId}
      />

      <Container>
        <div style={{ display: "flex", gap: 30, alignItems: "center" }}>
          <Typography style={{ fontWeight: 500, fontSize: "1.3em" }}>
            Ações
          </Typography>
          <ActionButton
            title="Adicionar novo projeto"
            onClick={() => setOpenNewProject(true)}
          />
          <ActionButton
            onClick={() => setAddCoordinator(true)}
            title="Adicionar Coordenador"
          />
          <ActionButton
            title="Ver detalhes da turma"
            onClick={() => setClassDetailsModal(true)}
          />
          <ActionButton
            title="Ver estatísticas das equipes"
            onClick={() => setModalTeamStats(true)}
          />
        </div>
      </Container>

      <Spacer size="3em" />

      <Container style={{ minHeight: "50vh", marginBottom: "3em" }}>
        {classDetails && classDetails.projects.length > 0 && (
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell align="left">Projeto</TableCell>
                <TableCell align="center">Pontos</TableCell>
                <TableCell align="center">Passo atual</TableCell>
                <TableCell align="center">Passo ideal</TableCell>
                <TableCell align="center">Problema</TableCell>
                <TableCell align="center">Solução</TableCell>
                <TableCell align="center">Modelo</TableCell>
                <TableCell align="right">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {classDetails.projects.map((p) => (
                <Row key={p.id}>
                  <TableCell component="th" scope="row" align="left">
                    {p.name}
                  </TableCell>
                  <TableCell align="center">
                    {p.score.toFixed(0)}/1000
                  </TableCell>
                  <TableCell align="center">{p.actualStep}</TableCell>
                  <TableCell align="center">
                    {classDetails.details.actualStep}
                  </TableCell>
                  <TableCell align="center">
                    {p.validations.val1
                      ? "Validado"
                      : !!p.steps[6].questions
                      ? "Pendente"
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {p.validations.val2
                      ? "Validado"
                      : !!p.steps[13].questions
                      ? "Pendente"
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {p.validations.val3
                      ? "Validado"
                      : !!p.steps[21].questions
                      ? "Pendente"
                      : "-"}
                  </TableCell>
                  <TableCell align="right" style={{ display: "flex", gap: 10 }}>
                    <IoEye
                      style={{ cursor: "pointer" }}
                      size={25}
                      onClick={() => {
                        localStorage.setItem("projectName", p.name);
                        localStorage.setItem("projectId", p.id);
                        push(`/project/${p.id}`);
                      }}
                    />
                    <IoPersonAdd
                      onClick={() => {
                        localStorage.setItem("projectName", p.name);
                        localStorage.setItem("projectId", p.id);
                        setSelectedProjectId(p.id);
                        setModalMemberToTeam(true);
                      }}
                      style={{ cursor: "pointer" }}
                      size={25}
                    />
                    <IoInformationCircle
                      onClick={() => {
                        localStorage.setItem("projectName", p.name);
                        localStorage.setItem("projectId", p.id);
                        setOpenTeamDetails(true);
                      }}
                      style={{ cursor: "pointer" }}
                      size={25}
                    />
                  </TableCell>
                </Row>
              ))}
            </TableBody>
          </Table>
        )}
        {classDetails && classDetails.projects.length === 0 && (
          <Typography>Essa turma não possui projetos cadastrados.</Typography>
        )}
      </Container>
      <NewProjectModal
        open={openNewProject}
        setOpen={setOpenNewProject}
        reloadProjects={fetchClassDetails}
        classId={id}
      />
      <Footer />
    </>
  );
};

export default ClassDetails;
