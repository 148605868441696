import { Container } from '@material-ui/core';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FiHome } from 'react-icons/fi';
import { useHistory } from 'react-router';
import { MainContainer, BreadCrumbItem, Separator } from './styles';

const Breadcrumb = ({ locations }) => {
  const { push } = useHistory();
  return (
    <Container>
      <MainContainer>
        <BreadCrumbItem onClick={() => push('/')}>
          <FiHome />
        </BreadCrumbItem>
        <Separator>
          <MdKeyboardArrowRight />
        </Separator>
        {locations.map((l) => (
          <>
            <BreadCrumbItem onClick={() => push(l.path || '/')}>
              {l.name || ''}
            </BreadCrumbItem>
            <Separator>
              <MdKeyboardArrowRight />
            </Separator>
          </>
        ))}
      </MainContainer>
    </Container>
  );
};

export default Breadcrumb;
