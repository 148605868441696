import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useState } from 'react';
import api from '../../../../services/api';

const NewClassModal = ({ open, setOpen, reloadClasses }) => {
  const ano = new Date()
  const [newClassDetails, setNewClassDetails] = useState({
    description: undefined,
    name: undefined,
    isOpen: true,
    startingDate: '',
    endingDate: '',
    stepsGoal: 23,
    year: ano.getFullYear(),
  });
  const [isFetching, setIsFething] = useState(false);
  const createNewClass = () => {
    setIsFething(true);
    api
      .createNewClass(newClassDetails)
      .then((res) => console.log(res.data))
      .catch((er) => console.log(er))
      .finally(() => {
        setIsFething(false);
        setOpen(false);
        setNewClassDetails({
          description: undefined,
          name: undefined,
          isOpen: true,
          year: undefined,
        });
        reloadClasses();
      });
  };
  // useEffect(() => console.log(newClassDetails), [newClassDetails]);
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>
        {isFetching ? 'Aguarde...' : 'Adicionar nova turma'}
      </DialogTitle>
      <DialogContent>
        <TextField
          required
          error={
            newClassDetails.name !== undefined &&
            newClassDetails.name.trim() === ''
          }
          onBlur={() =>
            newClassDetails.name === undefined &&
            setNewClassDetails({ ...newClassDetails, name: '' })
          }
          label="Nome da turma"
          variant="outlined"
          onChange={(e) =>
            setNewClassDetails({ ...newClassDetails, name: e.target.value })
          }
          value={newClassDetails.name}
          style={{ display: 'block', marginBottom: '1.5em' }}
          fullWidth
          disabled={isFetching}
        />
        <TextField
          required
          error={
            newClassDetails.description !== undefined &&
            newClassDetails.description.trim() === ''
          }
          onBlur={() =>
            newClassDetails.description === undefined &&
            setNewClassDetails({ ...newClassDetails, description: '' })
          }
          label="Descrição da turma"
          variant="outlined"
          onChange={(e) =>
            setNewClassDetails({
              ...newClassDetails,
              description: e.target.value,
            })
          }
          value={newClassDetails.description}
          multiline
          rows={2}
          style={{ display: 'block', marginBottom: '1.5em' }}
          fullWidth
          disabled={isFetching}
        />
        <TextField
          required
          // error={
          //   newClassDetails.year !== undefined &&
          //   (newClassDetails.year.trim() === '' ||
          //     !/^[0-9]+$/.test(newClassDetails.year))
          // }
          // onBlur={() =>
          //   newClassDetails.year === undefined &&
          //   setNewClassDetails({ ...newClassDetails, year: '' })
          // }
          // label="Data de início"
          type="date"
          variant="outlined"
          onChange={(e) =>
            setNewClassDetails({
              ...newClassDetails,
              startingDate: e.target.value,
            })
          }
          value={newClassDetails.startingDate}
          style={{ display: 'inline-block', marginBottom: '1.5em' }}
          disabled={isFetching}
        />
        <TextField
          required
          // error={
          //   newClassDetails.year !== undefined &&
          //   (newClassDetails.year.trim() === '' ||
          //     !/^[0-9]+$/.test(newClassDetails.year))
          // }
          // onBlur={() =>
          //   newClassDetails.year === undefined &&
          //   setNewClassDetails({ ...newClassDetails, year: '' })
          // }
          // label="Data de finalização"
          type="date"
          variant="outlined"
          onChange={(e) => {
            // console.log(Date(e.target.value));
            setNewClassDetails({
              ...newClassDetails,
              endingDate: e.target.value,
            });
          }}
          value={newClassDetails.endingDate}
          style={{
            display: 'inline-block',
            marginBottom: '1.5em',
            marginLeft: '1em',
          }}
          disabled={isFetching}
        />
        <TextField
          onChange={(e) => {
            // console.log(Date(e.target.value));

            if (e.target.value >= 1 && e.target.value <= 22)
              setNewClassDetails({
                ...newClassDetails,
                stepsGoal: e.target.value,
              });
          }}
          value={newClassDetails.stepsGoal}
          style={{
            display: 'inline-block',
            marginBottom: '1.5em',
            marginLeft: '1em',
            maxWidth: '12em',
          }}
          variant="outlined"
          type="number"
          label="Meta de passos"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
              setNewClassDetails({
                ...newClassDetails,
                name: undefined,
                description: undefined,
                year: undefined,
              });
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // console.log(newClassDetails.startingDate);
              createNewClass()
            }}
            disabled={
              isFetching ||
              newClassDetails.description === '' ||
              newClassDetails.name === '' ||
              newClassDetails.year === '' ||
              !/^[0-9]+$/.test(newClassDetails.year)
            }
          >
            Adicionar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NewClassModal;
