import styled from 'styled-components';

export const ActionContainer = styled.div`
  padding: 1em;
  box-shadow: 4px 6px 10px -8px rgba(0, 0, 0, 0.52);
  padding: 1em 1.6em;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  background-color: white;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in;
  background-color: ${({ pitch }) => (pitch ? 'orange' : 'rgb(251, 251, 251)')};
  &:hover {
    transform: scale(1.03);
    background-color: ${({ pitch }) =>
      pitch ? 'orange' : 'rgb(251, 251, 251)'};
  }
`;
