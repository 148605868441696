import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import Spacer from '../../../../components/Spacer';
import api from '../../../../services/api';

const AddUserToTeam = ({ userId, userName, open, setOpen }) => {
  const [tabNumber, setTabNumber] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [projectsList, setProjectsList] = useState([]);
  const [selectedProject, setSelectedProject] = useState({ name: '', id: '' });

  const fetchProjectsList = () => {
    setFetching(true);
    api.getClassesSimpleList().then((res) => {
      setProjectsList(res.data);
      setFetching(false);
    });
  };

  const addUserToClass = () => {
    // console.log(userId, userName);
    api.addUserToClass(selectedProject.id, userId, userName).then(() => {
      setOpen(false);
    });
  };

  useEffect(fetchProjectsList, []);
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        if (!fetching) {
          setOpen(false);
          setTabNumber(0);
        }
      }}
    >
      <DialogTitle style={{ textAlign: 'center' }}>
        {fetching
          ? 'Aguarde...'
          : tabNumber === 0
          ? `Selecione uma turma para adicionar ${userName}`
          : ''}
      </DialogTitle>
      {!fetching && (
        <DialogContent>
          {tabNumber === 0 ? (
            <div
              style={{
                display: 'flex',
                gap: 15,
                paddingBottom: '1em',
                flexWrap: 'wrap',
              }}
            >
              {projectsList.map((p) => (
                <div
                  onClick={() => {
                    setTabNumber(1);
                    // console.log(p);
                    setSelectedProject(p);
                  }}
                  style={{
                    cursor: 'pointer',
                    padding: '1em',
                    backgroundColor: 'rgb(250, 250, 250)',
                    boxShadow: '4px 6px 10px -8px rgba(0, 0, 0, 0.52)',
                    border: ' 1px rgba(0, 0, 0, 0.1) solid',
                    borderRadius: 9,
                  }}
                >
                  <Typography>{p.name}</Typography>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img style={{ width: '30%' }} src="user-add.png" alt="" />
              </div>
              <Spacer />
              <Typography style={{ fontSize: '1.3em', textAlign: 'center' }}>
                Deseja confirmar a adição de <strong>{userName}</strong> à turma{' '}
                <strong>{selectedProject.name}</strong>?
              </Typography>
              <Spacer size="2em" />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => setTabNumber(0)}
                >
                  Selecionar outra turma
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={addUserToClass}
                >
                  Confirmar
                </Button>
              </div>
              <Spacer size="2em" />
            </div>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AddUserToTeam;
