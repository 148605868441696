import { Button, TextField, Typography } from '@material-ui/core';
import { useState } from 'react';
import api from '../../services/api';

const Login = () => {
  const [data, setData] = useState({ email: '', pass: '' });
  const [hasError, setHasError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const login = () => {
    setFetching(true);
    setHasError(false);
    api
      .login(data)
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data));
        // eslint-disable-next-line no-restricted-globals
        location.href = '/';
      })
      .catch(() => setHasError(true))
      .finally(() => setFetching(false));
  };
  return (
    <div
      style={{
        backgroundColor: 'gray',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '30%',
          minWidth: '450px',
          boxSizing: 'border-box',
          padding: '4em',
          backgroundColor: 'white',
        }}
      >
        <TextField
          required
          label="Usuário"
          variant="outlined"
          onChange={(e) => setData({ ...data, email: e.target.value })}
          value={data.email}
          style={{ display: 'block', marginBottom: '1.5em' }}
          fullWidth
          // disabled={isFetching}
        />
        <TextField
          required
          type="password"
          label="Senha"
          variant="outlined"
          onChange={(e) => setData({ ...data, pass: e.target.value })}
          value={data.pass}
          style={{ display: 'block', marginBottom: '1.5em' }}
          fullWidth
          // disabled={isFetching}
        />
        {hasError && (
          <Typography
            style={{ color: 'red', textAlign: 'center', marginBottom: '1.5em' }}
          >
            Usuário ou senha incorretos
          </Typography>
        )}
        <Button
          fullWidth
          onClick={login}
          variant="contained"
          size="large"
          disabled={data.pass === '' || data.email === '' || fetching}
        >
          {fetching ? 'Aguarde...' : 'ACESSAR'}
        </Button>
      </div>
    </div>
  );
};

export default Login;
