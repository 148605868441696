import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import api from '../../services/api';
import { FiClock, FiCheckCircle } from 'react-icons/fi';
import { FaChessKing } from 'react-icons/fa';
import { Row, StatusChip } from './styles';
import AddUserToTeam from './Modals/AddUserToTeam';
import Spacer from '../../components/Spacer';

const AllUsers = () => {
  const [allUsers, setAllUsers] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [addTeam, setAddTeam] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ name: '', id: '' });
  const [activatingUser, setActivatingUser] = useState(false);
  const [changingUserRole, setChangingUserRole] = useState(false);

  const filterUsers = (searchTerm) => {
    if (searchTerm === '') setFilteredUsers(allUsers);
    else
      setFilteredUsers(
        allUsers.filter((user) => {
          return (user.email + user.name)
            .toUpperCase()
            .includes(searchTerm.toUpperCase());
        })
      );
  };

  const changeUserRole = (userId, role = 'user') => {
    setChangingUserRole(true);
    api
      .changeUserRole(userId, role)
      .then((res) => {
        setAllUsers(res.data);
        setFilteredUsers(res.data);
      })
      .finally(() => setChangingUserRole(false));
  };

  const activateUser = (userId) => {
    setActivatingUser(true);
    api
      .activateUser(userId)
      .then((res) => {
        setAllUsers(res.data);
        setFilteredUsers(res.data);
      })
      .finally(() => {
        setActivatingUser(true);
        setActivatingUser(false);
      });
  };

  const fetchAllUsers = () => {
    api.getUsers().then((res) => {
      setAllUsers(res.data);
      setFilteredUsers(res.data);
    });
  };

  useEffect(fetchAllUsers, []);
  return (
    <>
      <AddUserToTeam
        open={addTeam}
        setOpen={setAddTeam}
        userId={selectedUser.id}
        userName={selectedUser.name}
      />
      <Header />
      <Breadcrumb locations={[{ name: 'Gestão de usuários', path: '/' }]} />
      <PageTitle title="Gestão de usuários">
        <TextField
          variant="outlined"
          label="Pesquisar"
          onChange={(e) => filterUsers(e.target.value)}
        />
      </PageTitle>
      {filteredUsers && filteredUsers.length > 0 && (
        <>
          <Container>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Nome</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Ações</TableCell>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="center">Turma</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((user, index) => (
                  <Row order={index} style={{ animationDelay: index * 300 }}>
                    <TableCell component="th" scope="row" align="left">
                      {user.name}
                    </TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">
                      {user.isActive ? (
                        <StatusChip
                          avatar={<FiCheckCircle color="white" />}
                          label="Ativo"
                        />
                      ) : (
                        <StatusChip
                          avatar={<FiClock color="#705614" />}
                          label="Pendente de aprovação"
                          color="yellow"
                        />
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {user.isActive ? (
                        <Button
                          disabled={
                            changingUserRole ||
                            !JSON.parse(localStorage.getItem('user'))
                              .superuser ||
                            user.superuser
                          }
                          variant="contained"
                          color={user.type === 'admin' ? 'secondary' : ''}
                          onClick={() =>
                            changeUserRole(
                              user.id,
                              user.type === 'admin' ? 'user' : 'admin'
                            )
                          }
                        >
                          {user.type === 'admin'
                            ? 'Remover direitos de admin'
                            : 'Promover a administrador'}
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          disabled={activatingUser}
                          variant="contained"
                          onClick={() => activateUser(user.id)}
                        >
                          {activatingUser ? 'Aguarde...' : 'Aceitar usuário'}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {user.type === 'admin' && <FaChessKing />}
                      {user.superuser && <FaChessKing />}
                      {user.type === 'user'
                        ? 'Usuário'
                        : user.superuser
                        ? ' Super Administrador'
                        : ' Administrador'}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedUser(user);
                          setAddTeam(true);
                        }}
                      >
                        Adicionar à turma
                      </Button>
                    </TableCell>
                  </Row>
                ))}
              </TableBody>
            </Table>
          </Container>
          <Spacer size="16em" />
        </>
      )}
      <Footer />
    </>
  );
};

export default AllUsers;
