/* eslint-disable no-restricted-globals */
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import AllClasses from './pages/AllClasses';
import AllUsers from './pages/AllUsers';
import ClassConfig from './pages/ClassConfig';
import ClassDetails from './pages/ClassDetails';
import Login from './pages/Login';
import Project from './pages/Project';
import StepAvaliation from './pages/StepAvaliation';

const App = () => {
  const getUser = () => localStorage.getItem('user');

  if (!sessionStorage.getItem('active')) {
    sessionStorage.setItem('active', true);
    location.href = '/';
  }
  if (!localStorage.getItem('logged')) {
    localStorage.removeItem('user');
    localStorage.setItem('logged', 'true');
    location.href = '/';
  }
  return (
    <Router>
      <Switch>
        {getUser() ? (
          <>
            <Route exact path="/" component={AllClasses} />
            <Route exact path="/class/:id" component={ClassDetails} />
            <Route exact path="/classconfig/:id" component={ClassConfig} />
            <Route path="/users" component={AllUsers} />
            <Route path="/project/:projectId" component={Project} />
            <Route
              path="/steps/:projectId/:stepNumber"
              component={StepAvaliation}
            />
          </>
        ) : (
          <Route path="/" component={Login} />
        )}
      </Switch>
    </Router>
  );
};

export default App;
