import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  //InputLabel,
  //Select,
  TextField,
} from '@material-ui/core';
//import { useEffect } from 'react';
import { useState } from 'react';
import api from '../../../../services/api';

const NewProjectModal = ({ open, setOpen, reloadProjects, classId }) => {
  // const [selectedUser, setSelectedUser] = useState('');
  const [newProjectDetails, setNewProjectDetails] = useState({
    description: '',
    name: '',
    classId,
  });
  const [isFetching, setIsFething] = useState(false);
  //const [isFetchingUsers, setFetchingUsers] = useState(true);
  //const [users, setUsers] = useState();

  /* const fetchUsers = () => {
    setFetchingUsers(true);
    api
      .getAllUsers()
      .then((res) => {
        setUsers(res.data);
      })
      .finally(() => setFetchingUsers(false));
  }; */

  const createNewProject = () => {
    setIsFething(true);
    api
      .createNewProject({ ...newProjectDetails /*, owner: selectedUser */ })
      .then(() => setOpen(false))
      .catch((er) => console.log(er))
      .finally(() => {
        setIsFething(false);
        reloadProjects();
      });
  };

  // useEffect(fetchUsers, []);
  // useEffect(() => console.log(newProjectDetails), [newProjectDetails]);
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>
        {isFetching ? 'Aguarde...' : 'Adicionar novo projeto'}
      </DialogTitle>
      <DialogContent>
        <TextField
          required
          label="Nome do projeto"
          variant="outlined"
          onChange={(e) =>
            setNewProjectDetails({ ...newProjectDetails, name: e.target.value })
          }
          value={newProjectDetails.name}
          style={{ display: 'block', marginBottom: '1.5em' }}
          fullWidth
          disabled={isFetching}
        />
        <TextField
          required
          label="Descrição do projeto"
          variant="outlined"
          onChange={(e) =>
            setNewProjectDetails({
              ...newProjectDetails,
              description: e.target.value,
            })
          }
          value={newProjectDetails.description}
          multiline
          rows={4}
          style={{ display: 'block', marginBottom: '1.5em' }}
          fullWidth
          disabled={isFetching}
        />
        {/* !isFetchingUsers && users && (
          <>
            <InputLabel htmlFor="outlined-age-native-simple">
              Proprietário
            </InputLabel>
            <Select
              fullWidth
              native
              onChange={(e) => setSelectedUser(e.target.value)}
              label="Proprietario"
              inputProps={{
                name: 'owner',
                id: 'outlined-age-native-simple',
              }}
            >
              <option value={null}></option>
              {users.map((user) => (
                <option value={user.id}>{user.name}</option>
              ))}
            </Select>
          </>
              )*/}
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button variant="contained" onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={createNewProject}
            disabled={isFetching}
          >
            Adicionar
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default NewProjectModal;
