import { Button, Chip } from '@material-ui/core';
import { useHistory } from 'react-router';
import { sliceText } from '../../utils/textFunctions';
import Spacer from '../Spacer';
import {
  LeftContainer,
  MainContainer,
  ProjectName,
  RightContainer,
  ProjectDescription,
  ProjectConclusion,
} from './styles';

const ClassCard = ({ details }) => {
  const { push } = useHistory();

  return (
    <MainContainer id={details.id}>
      <LeftContainer>
        <ProjectName>{details.name}</ProjectName>
        <Spacer />
        <ProjectDescription>
          {sliceText(details.description)}
        </ProjectDescription>
        <Spacer size="1em" />
        <Chip label={details.year} />
        <Spacer size="1em" />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            localStorage.setItem('className', details.name);
            localStorage.setItem('classId', details.id);
            push(`/class/${details.id}`);
          }}
        >
          Ver detalhes
        </Button>
      </LeftContainer>
      <RightContainer>
        <div>
          <ProjectConclusion>
            {details.isOpen ? 'Aberta' : 'Encerrada'}
          </ProjectConclusion>
        </div>
      </RightContainer>
    </MainContainer>
  );
};

export default ClassCard;
