import { Container, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ActionButton from '../../components/ActionButton';
import Breadcrumb from '../../components/Breadcrumb';
import ClassCard from '../../components/ClassCard';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import Spacer from '../../components/Spacer';
import api from '../../services/api';
import NewClassModal from './components/NewClassModal';

const AllClasses = () => {
  const [classes, setClasses] = useState([]);
  const [fetchingClasses, setFetchingClasses] = useState(true);
  const [openNewClass, setOpenNewClass] = useState(false);
  const fetchClasses = () => {
    api
      .getAllClasses()
      .then((res) => {
        // console.log(res.data);
        setClasses(res.data);
        setFetchingClasses(false);
      })
      .catch((er) => console.log(er));
  };
  useEffect(fetchClasses, []);
  return (
    <>
      <Header />
      <Breadcrumb locations={[{ name: 'Turmas', path: '/' }]} />
      <PageTitle title="Todas as turmas" />
      {!!JSON.parse(localStorage.getItem('user')).superuser && (
        <>
          <Container>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <Typography style={{ fontWeight: 500, fontSize: '1.3em' }}>
                Ações
              </Typography>
              <ActionButton
                title="Criar nova turma"
                onClick={() => setOpenNewClass(true)}
              />
            </div>
          </Container>

          <Spacer size="3em" />
        </>
      )}
      <Container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        {!fetchingClasses &&
          classes
            .filter((c) => {
              if (!!JSON.parse(localStorage.getItem('user')).superuser)
                return true;
              return (
                c.allUsers.some(
                  (u) =>
                    u.userId === JSON.parse(localStorage.getItem('user')).id
                ) ||
                c.allCoordinators.some(
                  (u) => u.id === JSON.parse(localStorage.getItem('user')).id
                )
              );
            })
            .map((c) => <ClassCard details={c} />)}
      </Container>
      <Container style={{ minHeight: fetchingClasses ? '50vh' : '20vh' }} />
      <NewClassModal
        open={openNewClass}
        setOpen={setOpenNewClass}
        reloadClasses={fetchClasses}
      />
      <Footer />
    </>
  );
};

export default AllClasses;
