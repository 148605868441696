import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import './index.css';

const Header = () => {
  const { push } = useHistory();
  const handleLogout = () => {
    localStorage.removeItem('user');
    // eslint-disable-next-line no-restricted-globals
    location.href = '/';
  };
  return (
    <div class="main-menu">
      <div className="menu-items">
        <div className="left-side">
          <li onClick={() => push('/')} style={{ cursor: 'pointer' }}>
            Turmas
          </li>
          <li onClick={() => push('/users')} style={{ cursor: 'pointer' }}>
            Gestão de usuários
          </li>
        </div>

        <div className="right-side">
          <Button
            style={{ padding: '0.6em 1em' }}
            variant="contained"
            color="primary"
            onClick={handleLogout}
          >
            Sair
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
